@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url('./Raleway-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}

body {
  margin: 0;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
